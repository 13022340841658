import { NextRequest, NextResponse } from 'next/server'
import {
  toGeneralStatic,
  toClubAlvi,
  toLocales,
  toConditionalBases,
  toHome,
  toExternal,
  toLanding
} from '../utils/seo/dataRedirect'
import { checkNonRoutes } from './url'

export interface MiddlewareUrlParamsProps {
  props: Array<string>;
  request: NextRequest;
}

export const middlewareUrlParams = ({ props, request }: MiddlewareUrlParamsProps) => {
  let params = props.reduce<string[]>((res, key) => {
    const val = request.nextUrl.searchParams.get(key)
    return val ? [...res, `${key}-${val}`] : res
  }, [])
    .sort()
    .join('/')

  if (request.nextUrl.pathname.startsWith('/brand')) {
    const schannel : string = request.cookies.get('co_sc')
    if(schannel){
      params = (params + `/${schannel}`)
    }
  }
  if(!params) return
  // next.js <= 12.0.8
  if (!request.nextUrl.clone) {
  // eslint-disable-next-line consistent-return
    return NextResponse.rewrite(
      `${request.nextUrl.pathname}/${params}`?.replace(/\/\//, '/')
    )
  }
  // next.js >= 12.0.9
  const url = request.nextUrl.clone()
  url.pathname = (url.pathname + `/${params}`)?.replace(/\/\//, '/')
  // eslint-disable-next-line consistent-return
  return NextResponse.rewrite(url)
}

export const normalizeString = (str: string) => {
  return str.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/,/g, '')
    ?.replace(/\s/g, '-')
    .toLowerCase()
}

const collectionIncludesEle = (collection, ele) => {
  const normalizeCategory = normalizeString(ele)
  return collection.indexOf(normalizeCategory) > -1
}

export const homeMiddleware = (request: NextRequest) => {
  const url = request.nextUrl.clone()
  const pathName = request.nextUrl.pathname

  /* Temporal solution to delete non pages for seo*/
  if (!request.nextUrl.pathname.startsWith('/') && !checkNonRoutes(pathName)){
    return NextResponse.redirect('/404')
  }

  if (toExternal[request.nextUrl.pathname.split('/')[1]]) {
    url.href = toExternal[request.nextUrl.pathname.split('/')[1]]
    return NextResponse.redirect(url, 301)
  }

  const baseCondicionales = collectionIncludesEle(
    toConditionalBases,
    request.nextUrl.pathname.split('/')[1]
  )
  if (baseCondicionales) {
    url.pathname = `/static/bases-condiciones`
    return NextResponse.redirect(url, 301)
  }

  const home = collectionIncludesEle(
    toHome,
    request.nextUrl.pathname.split('/')[1]
  )
  if (home) {
    url.pathname = '/'
    return NextResponse.redirect(url, 301)
  }

  const locales = collectionIncludesEle(
    toLocales,
    request.nextUrl.pathname.split('/')[1]
  )
  if (locales) {
    url.pathname = '/locales-y-horarios'
    return NextResponse.redirect(url, 301)
  }

  const clubAlvi = collectionIncludesEle(
    toClubAlvi,
    request.nextUrl.pathname.split('/')[1]
  )
  if (clubAlvi) {
    url.pathname = '/static/club-alvi'
    return NextResponse.redirect(url, 301)
  }

  if (
    request.nextUrl.pathname.split('/')[1] === 'beneficio' &&
    request.nextUrl.pathname.split('/')[2] === 'credito-alvi'
  ) {
    url.pathname = `/static/${pathName.split('/')[2].replace(/\/p$/,'')}`
    return NextResponse.redirect(url, 301)
  }

  if (request.nextUrl.pathname.split('/')[1] === 'ofertas') {
    url.pathname = '/ofertas/ofertas-destacadas'
    return NextResponse.redirect(url, 301)
  }

  const landings = collectionIncludesEle(
    toLanding,
    request.nextUrl.pathname.split('/')[1]
  )
  if (landings) {
    url.pathname = `/page${pathName.replace(/\/p$/,'')}`
    return NextResponse.redirect(url, 301)
  }

  const generals = collectionIncludesEle(
    toGeneralStatic,
    request.nextUrl.pathname.split('/')[1]
  )
  if (generals) {
    url.pathname = `/static${pathName.replace(/\/p$/,'')}`
    return NextResponse.redirect(url, 301)
  }

  return NextResponse.next()
}