import { Home } from 'components'
import { getContentFulComponents } from 'shared/helpers'
import { MetaHead } from 'components/MetaHead'
import { dehydrate } from '@smu-chile/pkg-unimarc-hooks'
import { PropsHome } from 'shared/interfaces/Home'

const Index = ({ data, isMobile }: PropsHome): React.ReactElement => {
  return (
    <>
      <MetaHead type='HOME'/>
      <Home data={data} isMobile={isMobile} />
    </>
  )
}

export async function getServerSideProps(context) {
  const UA = context.req.headers['user-agent']
  const isMobile = Boolean(UA.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  ))
  const contentOptions = {
    options: {
      content_type: 'home',
      'fields.idFormato': 3,
      limit: 1,
      include: 2
    }
  }
  const {
    response: data,
    queryClient
  } = await getContentFulComponents({contentOptions})

  return {
    props: {
      data,
      isMobile,
      dehydratedState: dehydrate(queryClient)
    }
  }
}
export default Index
