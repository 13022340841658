import { normalizeString } from '../../shared/helpers/middleware'

const webSiteSchema = JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  'name': 'Alvi',
  'url': 'https://www.alvi.cl',
  'potentialAction': {
    '@type': 'SearchAction',
    'target': 'https://www.alvi.cl/search?q={search_term_string}',
    'query-input': 'required name=search_term_string'
  }
})

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  'name': 'Alvi',
  'alternateName': 'Club Alvi',
  'url': 'https://www.alvi.cl',
  'logo': 'https://alvi.cl/favicon.ico',
  'sameAs': [
    'https://www.facebook.com/supermercadoalvi/',
    'https://twitter.com/AlviChile',
    'https://www.instagram.com/clubalvi',
    'https://www.youtube.com/channel/UCpx-3fTvUPTiTiupCG45-4g'
  ]
})

const breadSchema = (categories, url, pageNumber) => {
  const categoryName = []
  const breadCrumbs = categories?.map?.((category, index) => {
    const normalizedCategory = normalizeString(category)
    categoryName.push(normalizedCategory)
    if (index === categories.length - 1 && pageNumber) {
      return {
        '@type': 'ListItem',
        'position': index + 2,
        'name': normalizedCategory,
        'item': `${url}${categoryName.join('/')}?page=${pageNumber}`
      }
    }
    return {
      '@type': 'ListItem',
      'position': index + 2,
      'name': normalizedCategory,
      'item': `${url}${categoryName.join('/')}`
    }
  })

  if (breadCrumbs.length === 0) {
    return null
  }

  breadCrumbs?.unshift({
    '@type': 'ListItem',
    'position': 1,
    'name': 'Inicio',
    'item': 'https://www.alvi.cl'
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadCrumbs
  })
}

const productSchema = (productInfo, canonicalUrl) => {
  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    'name': productInfo?.name,
    'image': productInfo?.images,
    'description': productInfo?.description,
    'sku': productInfo?.sku,
    'brand': {
      '@type': 'Brand',
      'name': productInfo?.brand
    },
    'offers': {
      '@type': 'Offer',
      'url': canonicalUrl,
      'priceCurrency': 'CLP',
      'price': productInfo?.price,
      'availability': `https://schema.org/${productInfo?.availability}`
    }
  })
}

export {
  webSiteSchema,
  organizationSchema,
  breadSchema,
  productSchema
}
